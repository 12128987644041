<template>
  <div :style="{ background: objSpecial.color }" class="dnf">
    <a-spin :spinning="loading" tip="Loading...">
      <div class="precincts">
        <p>
          <img :src="objSpecial.topImg" alt="" />
        </p>
        <!--      <h3 :style="{ color: objSpecial.color }" class="title_img">{{ objSpecial.name }}</h3>-->
      </div>
      <div class="details">
        <div v-if="precinctData.length" class="details_info">
          <list-card :list="precinctData" :size='pages.size'></list-card>
        </div>
        <div v-else-if="precinctData.length<=0" style="height: 65vh;padding: 50px">
            <a-empty />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>

import { getSpecial } from '@/api/Precinct'

import listCard from '@/components/listCard/card'


export default {
  name: 'Prefecture',
  components: {
    listCard
  },
  data() {
    return {
      ids: this.$route.query.id || '',
      loading: true,
      show: true,
      objSpecial: {},
      precinctData: [],
      pagesZong: 0,  // 数据总页数
      pageSizeOptions: ['12', '22', '32'],
      pages: {
        size: 36,
        total: 1,
        current: 1
      },
      isLoading: false
    }
  },
  computed: {

  },
  created() {
    this.initPrecinctList()

  },
  // 离开时销毁
  beforeDestroy() {
    window.removeEventListener('scroll',this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll() {
      var that = this;
      //滚动条在y轴上的滚动距离
      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight
      //滚动条到底部的条件
      // console.log('scrollHeight', Number(scrollHeight-720))
      // console.log('scrollTop',Number(scrollTop+windowHeight-720))

      if( Number(scrollTop+windowHeight-720) ==Number(scrollHeight-720) &&this.precinctData.length !==0){
        this.onLoads() // 加载的列表数据
      }

    },

    initPrecinctList() {
      let id = this.ids
      let obj = {
        current: this.pages.current,
        size: this.pages.size,
        id
      }
      getSpecial(obj).then(res => {
        this.loading = false
        let productPage = res.data.supplyProductPage
        this.objSpecial = res.data
        if (productPage.total !== 0) {
          // if (this.pages.current===productPage.pages){
          //   this.show = false
          // }
          this.precinctData = productPage.records
          this.pages.total = productPage.total
          this.pages.size = productPage.size
          this.pagesZong = productPage.pages
        }
      })
    },

    /* 懒加载分页 */
    onLoads() {
      if (this.pages.total > 36 && this.pagesZong !== this.pages.current) {
        // this.loading = true
        this.pages.current++
        this.pages.size = this.pages.size + 15
        let id = this.$route.query.id || ''
        let obj = {
          current: this.pages.current,
          size: 36,
          id
        }
        if (this.ids) {
          getSpecial(obj).then(res => {
            let productPage = res.data.supplyProductPage
            if (this.pages.current === productPage.pages) {
              this.show = false
            }
            this.precinctData = this.precinctData.concat(productPage.records)
            this.loading = false
          })
        }

      }
    },
  },
}
</script>

<style lang='scss' scoped>
.dnf {
  width: 100%;
  padding: 0 !important;

  .details {
    max-width: 1200px;
    margin: 0 auto;

    .load_button {
      margin: 30px auto;
      border-radius: 4px;
      width: 320px;
      height: 40px;
      line-height: 40px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #fff;
      text-align: center;
      //background: #f10180;
      cursor: pointer;
    }

    .details_info {
      // box-shadow: 0 0 5px 0 #c0c4cc;
      margin-bottom: 25px;
      overflow: hidden;
      padding-bottom: 13px;
      margin-bottom: 20px;
    }
  }

  .precincts {

    //max-width: 1200px;
    //margin: 0 auto;
    .title_img {
      height: 130px;
      line-height: 130px;
      text-align: center;
    }

    p {
      height: 300px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
